var supportsOrientationChange = 'onorientationchange' in window, orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize',
	htmlTimer,
	isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)),
	language = document.documentElement.lang.substring(0, 2);

$('html').css('--vh', window.innerHeight/100 + 'px');

window.addEventListener('resize', (function(){
	clearTimeout(htmlTimer);
	htmlTimer = setTimeout(function(){
		$('html').css('--vh', window.innerHeight/100 + 'px');
	}, 50);
}), false);

if( isTouch == true ){
	document.getElementsByTagName('html')[0].className += ' touchevents';
} else {
	document.getElementsByTagName('html')[0].className += ' no-touchevents';
}

var areClipPathShapesSupported = function(){

	var base = 'clipPath',
		prefixes = ['webkit', 'moz', 'ms', 'o'],
		properties = [base],
		testElement = document.createElement('testelement'),
		attribute = 'polygon(50% 0%, 0% 100%, 100% 100%)';

	for( var i = 0, l = prefixes.length; i < l; i++){
		var prefixedProperty = prefixes[i] + base.charAt(0).toUpperCase() + base.slice(1);
	  	properties.push(prefixedProperty);
	}

	for( var i = 0, l = properties.length; i < l; i++){
	  	var property = properties[i];

	  	if( testElement.style[property] === '' ){
			testElement.style[property] = attribute;
			if( testElement.style[property] !== '' ){
		  		return true;
			}
	  	}
	}

	return false;
};

if(!areClipPathShapesSupported() || '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style){
	document.getElementsByTagName('html')[0].className += ' no-clip-path';
}

var positionStickySupport = function(){
	var el = document.createElement('a'),
		mStyle = el.style;
		mStyle.cssText = "position:sticky;position:-webkit-sticky;position:-ms-sticky;";

	if( mStyle.position.indexOf('sticky')!==-1 === false ){
		document.getElementsByTagName('html')[0].className += ' no-position-sticky';
	}
}();


function Device_Type(){
    var Return_Device;

	if(/(up.browser|up.link|mmp|symbian|smartphone|midp|wap|phone|android|iemobile|w3c|acs\-|alav|alca|amoi|audi|avan|benq|bird|blac|blaz|brew|cell|cldc|cmd\-|dang|doco|eric|hipt|inno|ipaq|java|jigs|kddi|keji|leno|lg\-c|lg\-d|lg\-g|lge\-|maui|maxo|midp|mits|mmef|mobi|mot\-|moto|mwbp|nec\-|newt|noki|palm|pana|pant|phil|play|port|prox|qwap|sage|sams|sany|sch\-|sec\-|send|seri|sgh\-|shar|sie\-|siem|smal|smar|sony|sph\-|symb|t\-mo|teli|tim\-|tosh|tsm\-|upg1|upsi|vk\-v|voda|wap\-|wapa|wapi|wapp|wapr|webc|winw|winw|xda|xda\-) /i.test(navigator.userAgent)){
        if(/(tablet|ipad|playbook)|(android(?!.*(mobi|opera mini)))/i.test(navigator.userAgent)){
            Return_Device = 'Tablet';
        } else {
            Return_Device = 'Mobile';
        }
    } else if(/(tablet|ipad|playbook)|(android(?!.*(mobi|opera mini)))/i.test(navigator.userAgent)){
        Return_Device = 'Tablet';
    } else {
        Return_Device = 'Desktop';
    }

	if( Return_Device != 'Mobile' ){
		$('.banner-placeholder').each(function(i, e){
			if( $(e).data('banner') != '' || $(e).data('banner') != undefined ){
				$(e).html('<a href="'+ $(e).data('banner-link') +'"><img src="'+ $(e).data('banner-image') +'" alt="Alt text" /></a>');
			}
		});
	}
}

Device_Type();

// Easing
jQuery.extend( jQuery.easing,{
	easeOutCubic: function(x, t, b, c, d) {return c*((t=t/d-1)*t*t + 1) + b;}
});

// Equal height
$.fn.equalHeights = function(){
	var max_height = 0;
	$(this).each(function(){
		$(this).css('min-height', 'auto');
		max_height = Math.max($(this).outerHeight(), max_height);
	});
	$(this).each(function(){
		$(this).css('min-height', Math.round(max_height));
	});
};

function changedSrc(videoSrc) {
	var mediaDesktop = window.matchMedia('(min-width: 1025px)');
	var mediaTablet = window.matchMedia('(min-width: 769px) and (max-width: 1024px)');
	var mediaMobile = window.matchMedia('(min-width: 320px) and (max-width: 768px)');

	if (mediaDesktop.matches && videoSrc.default) {
		$(this).attr('src', videoSrc.default);
	} else if (mediaTablet.matches && videoSrc.tablet) {
		$(this).attr('src', videoSrc.tablet);
	} else if (mediaMobile.matches && videoSrc.mobile) {
		$(this).attr('src', videoSrc.mobile);
	} else {
		$(this).attr('src', videoSrc.default);
	}
}

function responsiveVideoSrc(videoItem) {
	$(videoItem).each(function() {
		var $this = $(this);
		var changeSrcFn = changedSrc.bind($this);
		var videoSrc = undefined;
		var isIE = /MSIE|Trident/.test(window.navigator.userAgent);

		if ($this.data('media-mp4') && !$this.data('media-webm')) {
			videoSrc = $.parseJSON(JSON.stringify($this.data('media-mp4')));
		}

		if ($this.data('media-webm') && !$this.data('media-mp4')) {
			videoSrc = $.parseJSON(JSON.stringify($this.data('media-webm')));
		}

		if ($this.data('media-webm') && $this.data('media-mp4')) {
			videoSrc = $.parseJSON(JSON.stringify($this.data('media-webm')));
		}

		if ( isIE && $this.data('media-mp4')) {
			videoSrc = $.parseJSON(JSON.stringify($this.data('media-mp4')));
		}

		if (videoSrc) {
			changeSrcFn(videoSrc);
		}

	});
}

$(document).ready(function(){

	if($('.js-video-src').length > 0) {
		responsiveVideoSrc([].concat(document.querySelectorAll('.js-video-src')));
	}

	// Main menu
	var menuTimer;

	$('.main-nav__list-item').each(function(){
		$(this).attr('data-width', parseInt($(this).width(), 10));
	});

	function calcMenuDimensions(){
		var menu_holder_width = $('.header-wrapper').width() - 100;
		var menu_width = 0;

		$('.main-nav__list-item').each(function(){
			menu_width += Number($(this).attr('data-width'));
		});

		if( menu_width >= menu_holder_width ){
			$('.header').addClass('header--mobile');
		} else {
			$('.header').removeClass('header--mobile');
		}
	}

	calcMenuDimensions();

	window.addEventListener(orientationEvent, (function(){
		calcMenuDimensions();
		clearTimeout(menuTimer);
		menuTimer = setTimeout(function(){
			calcMenuDimensions();
		}, 50);
	}), false);

	$('.main-nav').addClass('main-nav--visible');

	$('.js-button__mobile-menu').on('click', function(){
		$('.header').toggleClass('header--expanded');
	});

	$('.js-main-nav__dropdown').on('click', function(){
		if( !$(this).parents('.main-nav__list-item').hasClass('main-nav__list-item--expanded') ){
			$('.main-nav__list-item').removeClass('main-nav__list-item--expanded');
			$(this).parents('.main-nav__list-item').addClass('main-nav__list-item--expanded');
		} else {
			$(this).parents('.main-nav__list-item').removeClass('main-nav__list-item--expanded');
		}
	});

	$(document.body).on('mouseenter', '.main-nav__list-item--has-dropdown', function(){
		if( !$('.header').hasClass('header--mobile') ){
			$('.main-nav__list-item').removeClass('main-nav__list-item--expanded');
		}
	}).on('click', function(e){
		if( !$('.header').hasClass('header--mobile') ){
			if( $(e.target).closest($('.main-nav__list-item--expanded')).length == 0 ){
				$('.main-nav__list-item').removeClass('main-nav__list-item--expanded');
			}
		}
	});

	$('.js-nav__dropdown').on('click', function(){
		if( !$(this).parents('.main-nav__list-item').hasClass('main-nav__list-item--expanded') ){
			$('.main-nav__list-item').removeClass('main-nav__list-item--expanded');
			$(this).parents('.main-nav__list-item').addClass('main-nav__list-item--expanded');
		} else {
			$(this).parents('.main-nav__list-item').removeClass('main-nav__list-item--expanded');
		}
	});

	// Temporary disable the dropdown's previous menu item to allow easier reach to the opened menu
	$('.js-nav__dropdown').mouseout(function () {
		var $previous = $(this).parents('.main-nav__list-item').prev();
		if ($(this).parents('.main-nav__list-item').hasClass('main-nav__list-item--expanded')) {
			$previous.addClass("main-nav__list-item--adjacent");
			var removeTemp = setTimeout(function () {
				$previous.removeClass("main-nav__list-item--adjacent");
			}, 800);
		} else {
			clearTimeout(removeTemp);
		}
	});

	$('.main-nav__menu-scrollbar .overview').css({ 'padding-left': 'calc(50vw - 16px - '+ $('.main-nav__menu-scrollbar .main-nav__menu-item:first').innerWidth()/2 +'px)' });

	$('.no-touchevents .main-nav__menu-scrollbar').tinyscrollbar({
		axis: 'x',
		wheelSpeed: 20
	});


	// Header
    enquire.register('screen and (max-width: 61.9em)', {
        deferSetup: true,
        match : function(){

			//var shown = false;

			if( $('.header').length > 0 ){
				$(window).on('scroll touchmove', function(){

					var scrollTop = $(window).scrollTop();

					if( scrollTop > 300 ){
						$('html').addClass('fixed-header');

						/*
						if( shown === false ){
							$('.header').removeClass('header--expanded');
						}

						shown = true;
						*/

					} else {
						$('html').removeClass('fixed-header');
					}

					if( scrollTop > 400 ){
						$('html').addClass('fixed-header-transition');
					} else {
						$('html').removeClass('fixed-header-transition');
					}

					if( scrollTop > 500 ){
						$('html').addClass('show-fixed-header');
					} else {
						$('html').removeClass('show-fixed-header');
						//shown = false;
					}

				}).trigger('scroll');
			}

        },
        unmatch : function(){

            $(window).off('scroll touchmove');

            $('html').removeClass('fixed-header fixed-header-transition show-fixed-header');

        }
    }, true);

	$('.header-top__dropdown-header').on('click', function(){
		$(this).parents('.header-top__dropdown').toggleClass('header-top__dropdown--active');
	});

	$(document.body).on('click', function(e){
		if( $(e.target).closest($('.header-top__dropdown')).length == 0 ){
			$('.header-top__dropdown').removeClass('header-top__dropdown--active');
		}
	});

	$('.js-open__search').on('click', function(){
		$('.header-search').toggleClass('header-search--active');
	});

	$('.js-close__search').on('click', function(){
		$('.header-search').removeClass('header-search--active');
	});

	$(document.body).on('click', function(e){
		if( $(e.target).closest($('.js-open__search')).length == 0 && $(e.target).closest($('.header-search')).length == 0 ){
			$('.header-search').removeClass('header-search--active');
		}
	});


	$('.js-button__dropdown').on('click', function(){

		if( $(this).parents('.button-wrap').hasClass('button-wrap--expanded') ){
			$(this).parents('.button-wrap').removeClass('button-wrap--expanded');
		} else {
			$('.button-wrap').removeClass('button-wrap--expanded');
			$(this).parents('.button-wrap').addClass('button-wrap--expanded');
		}
	});

	$(document.body).on('click', function(e){
		if( $(e.target).closest($('.button-wrap')).length == 0 ){
			$('.button-wrap').removeClass('button-wrap--expanded');
		}
	});



	// Presentation
	if( $('.presentation').length > 0 ){

		expanded_index = $('.presentation').data('expanded');

		if(!isTouch){
			var lFollowX = 0,
				lFollowY = 0,
				x = 0,
				y = 0,
				friction = 1 / 15;

			$(document.body).on('mouseenter', '.kwicks-completed', function(){

				var lFollowX = 0,
					lFollowY = 0,
					x = 0,
					y = 0;

			}).on('mousemove', '.kwicks-completed', function(e){

				var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
				var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
				lFollowX = (15 * lMouseX) / 100;
				lFollowY = (22.5 * lMouseY) / 100;

				x += (lFollowX - x) * friction;
				y += (lFollowY - y) * friction;

				translate = 'translate3d(' + x + 'px, ' + y + 'px, 0px)';

				$('.kwicks-completed picture').css({
					'-webit-transform': translate,
					'-moz-transform': translate,
					'transform': translate
				});

			});
			/*.on('mouseleave', '.kwicks-completed', function(e){

				var lFollowX = 0,
					lFollowY = 0,
					x = 0,
					y = 0;

				translate = 'translate3d(0px, 0px, 0px)';

				$('.kwicks-completed picture').css({
					'-webit-transform': translate,
					'-moz-transform': translate,
					'transform': translate
				});

			});
			*/
		}

		function initPresentationKwicks(){

			if( $('.presentation').hasClass('slick-slider') ){
				$('.presentation').slick('unslick');
			}

			var $presentation_kwicks = $('.presentation').kwicks({
				minSize: '104px',
				spacing: 1,
				duration: 300,
				behavior: 'menu'
			}).on('expand-complete.kwicks', function(e, data){
				expanded_index = data.index;

				$('.presentation__item:not(.kwicks-completed) picture').removeAttr('style');
			});

			$presentation_kwicks.kwicks('expand', expanded_index);
		}
		initPresentationKwicks();


		function destroyPresentationKwicks(){
			$presentation_kwicks = $('.presentation').kwicks('destroy');
			$('.presentation__item picture').removeAttr('style');
		}

		function initPresentationSlick(){

			var presentation_slick = $('.presentation').slick({
				speed: 450,
				arrows: false,
				dots: true,
				adaptiveHeight: true,
				touchThreshold: 100
			}).on('afterChange', function(event, slick, currentSlide){
				expanded_index = currentSlide;
			});

			presentation_slick.slick('slickGoTo', expanded_index);

			setTimeout(function(){
				$('.presentation').removeClass('kwicks-processed kwicks-horizontal');
				$('.presentation__item').removeAttr('style').removeClass('kwicks-expanded kwicks-completed kwicks-collapsed');
			}, 100);

		}

		enquire.register("screen and (max-width:  989px)", {
			match : function(){
				destroyPresentationKwicks();
				initPresentationSlick();
			},
			unmatch: function(){
				initPresentationKwicks();
			}
		}, true);
	}


	// Needs
	$('.needs-selected').on('click', function(){
		$('.needs').toggleClass('needs--active');
		$('.needs-options').slideToggle(250, 'easeOutCubic');
	});

	$('.needs-list__button').on('click', function(){

		var that = $(this);

		$('.needs').removeClass('needs--active');
		$('.needs-selected').html( $(this).html() );
		$('.js-needs__link').attr('href', $(that).data('link'));

		$('.needs-options').slideUp(250, 'easeOutCubic', function(){
			$('.needs-list__button').removeClass('active');
			$(that).addClass('active');
		});
	});


	// Selected slider
	if( $('.selected-slider').length > 0 ){

		var selected_items = 0,
			selected_items_by = 3;

		function initSelectedSlider(){
			$('.selected-slider__holder').each(function(i, e){

				var selected_items = $('.selected-slider__item', e).length;

				$('.selected-slider', e).on('init', function (event, slick, direction){
					if( slick.options.slidesToShow >= slick.slideCount ){
						$(e).addClass('hide-controls');
					}
				}).slick({
					speed: 450,
					arrows: true,
					dots: false,
					adaptiveHeight: true,
					appendArrows: $('.selected-slider__controls', e),
					slidesToShow: 3,
					slidesToScroll: 3,
					touchThreshold: 100,
					responsive: [
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2
							}
						}
					]
				});

			});
		}


		$('.selected-slider__holder').each(function(i, e){

			selected_items = $('.selected-slider__item', e).length;

			if( selected_items <= 3 ){
				$('.js-load__selected', e).addClass('el-hide');
			}

			$('.js-load__selected', e).on('click', function(){
				shown_items = $('.selected-slider__item.el-show', e).length + selected_items_by;
				$('.selected-slider__item:lt('+shown_items+')', e).addClass('el-show');

				if( shown_items >= selected_items ){
					$(this).addClass('el-hide');
				}
			});
		});


		function DestroySelectedSlider(){
			$('.selected-slider').each(function(i, e){
				if( $(e).hasClass('slick-slider') ){
					$('.selected-slider').slick('unslick');
					$('.selected-slider__item:lt('+ selected_items_by +')', e).addClass('el-show');
				}
			});
		}

		initSelectedSlider();

		enquire.register("screen and (max-width:  37.9em)", {
			match : function(){
				DestroySelectedSlider();
			},
			unmatch: function(){
				initSelectedSlider();
			}
		}, true);
	}



	// Similar slider
	if( $('.similar-slider').length > 0 ){
		$('.similar-slider__holder').each(function(i, e){

			$('.similar-slider', e).on('init', function (event, slick, direction){
				if( slick.options.slidesToShow >= slick.slideCount ){
					$(e).addClass('hide-controls');
				}
			}).slick({
				speed: 450,
				arrows: true,
				dots: false,
				adaptiveHeight: true,
				appendArrows: $('.similar-slider__controls', e),
				slidesToShow: 3,
				slidesToScroll: 3,
				touchThreshold: 100,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 767,
						settings: {
							variableWidth: true,
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: false,
							dots: true
						}
					}
				]
			});

		});
	}


	// Cards slider
	if( $('.cards-slider').length > 0 ){

		$('.cards-slider__holder').each(function(i, e){

			var cards_slider = $('.cards-slider', e).on('afterChange init', function(event, slick, direction){

				$('.slick-slide', e).removeClass('prev-slide next-slide');

				$('.slick-current', e).prev().addClass('prev-slide');
				$('.slick-current', e).next().addClass('next-slide');

			}).on('beforeChange', function(event, slick){

				$('.slick-slide', e).removeClass('prev-slide next-slide');

			}).slick({
				speed: 900,
				arrows: false,
				dots: true,
				adaptiveHeight: true,
				touchThreshold: 100,
				cssEase: 'cubic-bezier(0.86, 0, 0.07, 1)',
				responsive: [
					{
						breakpoint: 1000,
						settings: {
							speed: 600,
							cssEase: 'ease'
						}
					}
				]
			});

			$(document.body).on('click', '.prev-slide', function(){
				cards_slider.slick('slickPrev');
			}).on('click', '.next-slide', function(){
				cards_slider.slick('slickNext');
			});

		});

	}


	// Partners slider
	if( $('.partners-slider').length > 0 ){
		$('.partners-slider__holder').each(function(i, e){

			$('.partners-slider', e).on('init', function (event, slick, direction){
				if( slick.options.slidesToShow >= slick.slideCount ){
					$(e).addClass('hide-controls');
				}
			}).slick({
				speed: 450,
				arrows: true,
				dots: false,
				adaptiveHeight: true,
				appendArrows: $('.partners-slider__controls', e),
				slidesToShow: 4,
				slidesToScroll: 4,
				touchThreshold: 100,
				responsive: [
					{
						breakpoint: 1000,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3
						}
					},
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
							arrows: false,
							dots: true
						}
					},
					{
						breakpoint: 560,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: false,
							dots: true
						}
					}
				]
			});

		});
	}

	/*
	$('.button').each(function(i, button){
		new ResizeSensor(button, function(){
			if( button.clientHeight > 40 ){
				$(button).addClass('button--two-lines');
			} else {
				$(button).removeClass('button--two-lines');
			}
		});
	});
	*/



	// Equal heights
	$('.accounts-grid__title').equalHeights();
	$('.currency-block__title').equalHeights();
	//$('.compare-item__preview').equalHeights();
	//$('.accounts-row .accounts-row__title').equalHeights();
	//$('.accounts-row__text').equalHeights();


	var resizeTimer;

	$(window).on('resize', function(e){
  		clearTimeout(resizeTimer);
  		resizeTimer = setTimeout(function(){

			$('.accounts-grid__title').equalHeights();
			$('.currency-block__title').equalHeights();
			//$('.compare-item__preview').equalHeights();
			//$('.accounts-row .accounts-row__title').equalHeights();
			//$('.accounts-row__text').equalHeights();

		}, 250);
	});


	// Cards banner
	if( $('.cards-banner').length > 0 ){

		$('.cards-banner__list').each(function(i, e){

			var cards_banner_slider = $(e).slick({
				speed: 450,
				arrows: false,
				dots: true,
				adaptiveHeight: true,
				variableWidth: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				touchThreshold: 100,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1000,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			});

		});

	}


	// Map filters
	$('.map-filter__dropdown-header').on('click', function(){
		$(this).parents('.map-filter__dropdown').toggleClass('dropdown-open');
	});

	$(document.body).on('click', function(e){
		if( $(e.target).closest($('.map-filter__dropdown')).length == 0 ){
			$('.map-filter__dropdown').removeClass('dropdown-open');
		}
	});

	$('.locations-map__sidebar-button').on('click', function(){
		$('.locations-map').toggleClass('map-shrinked');
	});


	// Tooltip
    if( $('.tippy').length > 0 ){

		$('.tippy').each(function(i, e){
			tippy(e, {
				content: $(e).attr('data-tip'),
				interactive: true,
				animateFill: false,
				arrow: true,
				trigger: 'mouseenter',
				delay: [0, 600],
				theme: 'fibank'
			})
		});
	}


	// Cards list
	$(document.body).on('click', '.js-expand__cards-list', function(){
		$(this).parents('.cards-list__wrap').addClass('cards-list--expanded');
	});


	// Bank packs
	if( $('.packs-list__holder').length > 0 ){

		$('.js-expand__packs-list').on('click', function(){
			var that = $(this);
			$('html, body').stop(true, true).animate({ scrollTop: $(that).parents('.packs-list__holder').offset().top }, 600, function(){
				$(that).parents('.packs-list__holder').addClass('packs-list__holder--expanded');
			});
		});

		/*
		var scrollPos = 0;

		document.body.addEventListener('scroll', function(){
  			if( document.body.getBoundingClientRect().top > scrollPos ){
				console.log('up')
			} else {
				console.log('down')
			}
  			scrollPos = document.body.getBoundingClientRect().top;
		});
		*/

	}


	/* Filters */
	$('.filter-dropdown__header').on('click', function(e){
		$(this).parents('.filter-dropdown').toggleClass('expanded');
	});

	$('.filter-dropdown__header-button').on('click', function(){
		var that = $(this);
		setTimeout(function(){
			that.parents('.filter-dropdown').removeClass('expanded active');
		}, 10);
	});

	$('.filter-dropdown__row-title').on('click', function(){
		if( $(this).parents('.filter-dropdown__row-item').hasClass('expanded') ){
			$(this).parents('.filter-dropdown__row-item').removeClass('expanded');
		} else {
			$('.filter-dropdown__row-item').removeClass('expanded');
			$(this).parents('.filter-dropdown__row-item').addClass('expanded');
		}
	});

	$(document.body).on('click', function(e){
		if( $(e.target).closest($('.filter-dropdown')).length == 0 ){
			$('.filter-dropdown').removeClass('expanded');
		}
	});


	// Dictionary
	if( $('.dictionary-grid').length > 0 ){

		var DictionaryGrid = new AwesomeGrid('.dictionary-grid')
			.gutters(20).grid(1)
			.mobile(2, 20)
			.tablet(3, 20)
			.desktop(3, 24)
			.tv(4, 24);

		if (typeof(Event) === 'function'){
			window.dispatchEvent(new Event('resize'));
		} else {
			var evt = window.document.createEvent('UIEvents');
			evt.initUIEvent('resize', true, false, window, 0);
			window.dispatchEvent(evt);
		}

		var $dictionarySearchTimer = null,
			$dictionaryMinLength = 3,
			$dictionarySearhDelay = 300,
			$dictionary_input = $('.js-dictionary__input'),
			$dictionary_clear = $('.js-dictionary__clear'),
			$dictionary_grid = $('.dictionary-grid'),
			$dictionary_context = $(".dictionary-grid__item");

		$dictionary_input.on('input', function(){

			clearTimeout($dictionarySearchTimer);

			var dictionary_term = this.value;

			if( dictionary_term.length <= $dictionaryMinLength){
				$dictionary_clear.addClass('el-hide');
				$dictionary_context.removeClass('el-hide').unmark();
				$('.dictionary-grid__header').removeClass('el-hide');
				$('.dictionary-results').removeClass('dictionary-results--visible');

				if (typeof(Event) === 'function') {
					window.dispatchEvent(new Event('resize'));
				} else {
					var UIEvent = window.document.createEvent('UIEvents');
					UIEvent.initUIEvent('resize', true, false, window, 0);
					window.dispatchEvent(UIEvent);
				}
				return;
			}

			$dictionarySearchTimer = setTimeout(function(){
				$dictionary_context.unmark({
					done: function(){

						$dictionary_context.mark(dictionary_term, {
							done: function(){
								$dictionary_context.not(':has(mark)').addClass('el-hide');
								$dictionary_clear.removeClass('el-hide');

								$('.dictionary-grid__header').addClass('el-hide');
								$('.dictionary-results').addClass('dictionary-results--visible');
								$('#dictionary_results_count').html( $dictionary_context.has('mark').length );

								if( $dictionary_context.has('mark').length == 0 ){
									$dictionary_grid.css('height', 'auto');
								}

								if (typeof(Event) === 'function') {
									window.dispatchEvent(new Event('resize'));
								} else {
									var UIEvent = window.document.createEvent('UIEvents');
									UIEvent.initUIEvent('resize', true, false, window, 0);
									window.dispatchEvent(UIEvent);
								}
							}
						});
					}
				});
			}, $dictionarySearhDelay);
		});

		/*
		$dictionary_input.on('input', function(){

			var dictionary_term = $(this).val();

			if( dictionary_term.length > 3 ){

				$dictionary_context.removeClass('el-hide').unmark();

				if(dictionary_term){
					$dictionary_context.mark(dictionary_term, {
						done: function(){
							$dictionary_context.not(':has(mark)').addClass('el-hide');
							$dictionary_clear.removeClass('el-hide');

							$('.dictionary-grid__header').addClass('el-hide');
							$('.dictionary-results').addClass('dictionary-results--visible');
							$('#dictionary_results_count').html( $dictionary_context.has('mark').length );

							if( $dictionary_context.has('mark').length == 0 ){
								$dictionary_grid.css('height', 'auto');
							}

						}
					});

				} else {

					$dictionary_clear.addClass('el-hide');
					$('.dictionary-grid__header').removeClass('el-hide');
					$('.dictionary-results').removeClass('dictionary-results--visible');
				}

				if (typeof(Event) === 'function') {
					window.dispatchEvent(new Event('resize'));
				} else {
					var UIEvent = window.document.createEvent('UIEvents');
					UIEvent.initUIEvent('resize', true, false, window, 0);
					window.dispatchEvent(UIEvent);
				}

			} else {

				$dictionary_clear.addClass('el-hide');
				$dictionary_context.removeClass('el-hide').unmark();
				$('.dictionary-grid__header').removeClass('el-hide');
				$('.dictionary-results').removeClass('dictionary-results--visible');

				if (typeof(Event) === 'function') {
					window.dispatchEvent(new Event('resize'));
				} else {
					var UIEvent = window.document.createEvent('UIEvents');
					UIEvent.initUIEvent('resize', true, false, window, 0);
					window.dispatchEvent(UIEvent);
				}
			}

		});
		*/

		$dictionary_clear.on('click', function(){
			$dictionary_context.removeClass('el-hide').unmark();
			$('.dictionary-grid__header').removeClass('el-hide');
			$('.dictionary-results').removeClass('dictionary-results--visible');
			$dictionary_clear.addClass('el-hide');
			$dictionary_input.val('');

			if (typeof(Event) === 'function') {
				window.dispatchEvent(new Event('resize'));
			} else {
				var UIEvent = window.document.createEvent('UIEvents');
				UIEvent.initUIEvent('resize', true, false, window, 0);
				window.dispatchEvent(UIEvent);
			}
		});

		if( window.location.hash && $(window.location.hash).length > 0 ){
			setTimeout(function(){
				var position = $(window.location.hash).offset().top.toFixed(0);
				$('body, html').animate({ scrollTop: position }, 600, 'easeOutCubic');
			}, 100);
		}

		$(document.body).on('click', '.dictionary-alphabet a[href^="#"]', function(e){
			if( $($(this).attr('href')).length > 0 && $($(this).attr('href')).hasClass('el-hide') == false ){
				var position = $($(this).attr('href')).offset().top.toFixed(0);
				$('body, html').animate({ scrollTop: position }, 600, 'easeOutCubic');
			} else {
				e.preventDefault();
			}
		});
	}


	// Currency
	if( $('.currency__block-currency').length > 0 ){
		$('.currency__block-currency').removeClass('el-show');
		$('.currency__block-currency[data-currency='+ $('.js-update__currency').val() +']').addClass('el-show');
	}

	$('.js-update__currency').on('change', function(){
		$('.currency__block-currency').removeClass('el-show');
		$('.currency__block-currency[data-currency='+ this.value +']').addClass('el-show');
	});


	if( $('.currency__block-goods').length > 0 ){
		$('.currency__block-goods').removeClass('el-show');
		$('.currency__block-goods[data-goods='+ $('.js-update__goods').val() +']').addClass('el-show');
	}

	$('.js-update__goods').on('change', function(){
		$('.currency__block-goods').removeClass('el-show');
		$('.currency__block-goods[data-goods='+ this.value +']').addClass('el-show');
	});


	// Select2
	if( $('.js-select:not(.custom-phone__select)').length > 0 ){

		$('.js-select:not(.custom-phone__select)').each(function(i, e){

			$('select', e).select2({
				dropdownParent: $(e),
				containerCssClass: $(e).prop('class'),
				minimumResultsForSearch: $('select', e).data('search'),
				language: language,
				width: '100%',

			}).on('select2:open', function(e){

				$('.select2-search__field').attr('placeholder', 'Търси ...').focus();

			}).on('select2:select', function(){

				$('select', e).trigger('input');
				$('.select2-selection__rendered', e).addClass('select2-selection__rendered--selected').attr('title', '');
				// console.log(this.value)
			});

			$('.select2-selection__rendered', e).attr('title', '');

		});
	}




	// System message
	$('.system-message__close').on('click', function(){
		$('.js-system-message-show').fadeIn(250);
		$(this).parents('.system-message-wrapper').find('.system-message').slideUp(250, 'easeOutCubic');
		if ($(window).width() > 990) {
			$(this).parents('.system-message-wrapper').siblings('.header').find('.header-top__wrapper-item:last-child').css({'transform': 'translateX(-30px)'});
		}
		if ($(window).width() <= 990) {
			$(this).parents('.system-message-wrapper').siblings('.header').find('.header-wrapper__menu-contacts').css({'transform': 'translateX(-30px)'});
			$(this).parents('.system-message-wrapper').siblings('.header').find('.js-button__mobile-menu').css({'transform': 'translateX(-30px)'});
		}

		if ($(window).width() <= 360) {
			$(this).parents('.system-message-wrapper').siblings('.header').find('.header-wrapper__menu-contacts').css({'transform': 'translateX(-14px)'});
			$(this).parents('.system-message-wrapper').siblings('.header').find('.js-button__mobile-menu').css({'transform': 'translateX(-14px)'});
		}

	});

	$('.js-system-message-show').on('click', function(){
		$(this).parents('.system-message-wrapper').siblings('.header').find('.header-top__wrapper-item:last-child').css({'transform': 'translateX(0)'});
		if ($(window).width() <= 990) {
			$(this).parents('.system-message-wrapper').siblings('.header').find('.js-button__mobile-menu').css({'transform': 'translate(0)'});
			$(this).parents('.system-message-wrapper').siblings('.header').find('.header-wrapper__menu-contacts').css({'transform': 'translateX(0)'});
		}
		$(this).parents('.system-message-wrapper').find('.system-message').slideDown(250, 'easeOutCubic');
		$(this).fadeOut(0);
	});

	if (!$('.system-message-wrapper').find('.system-message').is(':visible')) {
		$('.system-message-wrapper').siblings('.header').find('.header-top__wrapper-item:last-child').css({'transform': 'translateX(-30px)'});
	}

	// is iOS
	if( ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0 ){
		document.getElementsByTagName('html')[0].className += ' is-ios';
	}

});



// Positions Accordion
function positionsAccordion(elem){

	$('.positions-list__header', elem).on('click', function(){

		if( $(this).parents('.positions-list__item').hasClass('expanded') ){

			$(this).parents('.positions-list__item').removeClass('expanded');
			$(this).parents('.positions-list__item').find('.positions-list__body').slideUp(350, 'easeOutCubic');

		} else if( $(this).parents('.positions-list__item').hasClass('positions-list__item--expanded') ){

			var that = $(this);

			$(this).parents('.positions-list__item').find('.positions-list__body').slideUp(350, 'easeOutCubic', function(){
				$(that).parents('.positions-list__item').removeClass('expanded positions-list__item--expanded');
			});

		} else {

			$('.positions-list__item', elem).removeClass('expanded');
			$('.positions-list__body', elem).slideUp(350, 'easeOutCubic');

			$(this).parents('.positions-list__item').addClass('expanded');
			$(this).parents('.positions-list__item').find('.positions-list__body').slideDown(350, 'easeOutCubic', function(){
				$('.positions-list__item', elem).removeClass('positions-list__item--expanded');
			});
		}

	});

}

if ( $('.positions-list').length > 0 ){
    $('.positions-list').each(function(){
        positionsAccordion($(this));
    });
}


// Accordion
function accordion(elem){

	$('.accordion-list__header', elem).on('click', function(){

		if( !$(this).parents('.accordion-list__item').hasClass('active-body') ){

			$(this).parents('.accordion-list__item').toggleClass('active');
			$(this).parents('.accordion-list__item').find('.accordion-list__body').stop(true, true).slideToggle(250, 'easeOutCubic');

		} else {

			var that = $(this);

			$(this).parents('.accordion-list__item').find('.accordion-list__body').stop(true, true).slideUp(250, 'easeOutCubic', function(){
				$(that).parents('.accordion-list__item').removeClass('active active-body');
			});
		}

	});

}

if ( $('.accordion-list').length > 0 ){
    $('.accordion-list').each(function(){
        accordion($(this));
    });
}


// FAQ
$.urlParam = function(name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if( results == null ){
       return null;
    }
    return decodeURI(results[1]) || 0;
}

if( $('.accordion-list--faq').length > 0 ){

	var $faqSearchTimer = null,
		$faqMinLength = 3,
		$faqSearhDelay = 300,
		$faq_container = $('.faq-container'),
		$faq_input = $('.js-faq__input'),
		$faq_clear = $('.js-faq__clear'),
		$faq_context = $('.accordion-list--faq .accordion-list__item'),
		faqTimer;

	$faq_input.on('input', function(){

		clearTimeout($faqSearchTimer);

		var faq_term = this.value;

		if( faq_term.length <= $faqMinLength){
			$faq_context.removeClass('accordion-list__item--active').unmark();
			$faq_container.removeClass('faq-container--filtered');
			$('#faq_results_count').html('0');
			$faq_clear.addClass('el-hide');
			$('.js-faq__results').prop('checked', false);
			$('.js-faq__results[value="all"]').prop('checked', true);
			$('.faq-group').removeClass('el-hide');
			return;
		}

		$faqSearchTimer = setTimeout(function(){
			$faq_context.unmark({
				done: function(){

					$faq_context.removeClass('accordion-list__item--active');
					$faq_container.removeClass('faq-container--filtered');
					$('#faq_results_count').html('0');

					$faq_context.mark(faq_term, {
						done: function(){
							$faq_container.addClass('faq-container--filtered');
							$faq_context.has('mark').addClass('accordion-list__item--active');

							$('#faq_results_count').html($faq_context.has('mark').length);
							$faq_clear.removeClass('el-hide');
						}
					});
				}
			});
		}, $faqSearhDelay);
	});


	if( $faq_input.val() != '' ){
		$faq_input.trigger('input');

		enquire.register("screen and (min-width:  61.9em)", {
			match : function(){
				$('body, html').stop(true, true).animate({ scrollTop: $('.faq-container').offset().top - 20 }, 600, 'easeOutCubic');
			}
		}, true);

		enquire.register("screen and (max-width:  61.9em)", {
			match : function(){
				$('.faq-container').removeClass('faq-container--active');
				$('body, html').stop(true, true).animate({ scrollTop: $('.faq-container').offset().top - 60 }, 600, 'easeOutCubic');
			}
		}, true);
	}


	$faq_clear.on('click', function(){
		$faq_container.removeClass('faq-container--filtered');
		$faq_context.removeClass('accordion-list__item--active').unmark();
		$faq_clear.addClass('el-hide');
		$faq_input.val('');
		$('.js-faq__results').prop('checked', false);
		$('.js-faq__results[value="all"]').prop('checked', true);
		$('.faq-group').removeClass('el-hide');

		$('html, body').stop(true, true).animate({ scrollTop: $('.main-content').offset().top }, 1000, 'easeOutCubic', function(){
			if( window.history.pushState ){
				var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
				window.history.pushState({ path: newurl }, '', newurl);
			}
		});

	});


	if( $.urlParam('category') != null ){

		var category = $.urlParam('category');

		setTimeout(function(){

			if( !$('.faq-container').hasClass('faq-container--filtered') && $('.faq-group[data-group="'+ category +'"]').length > 0 ){

				var position = $('.faq-group[data-group="'+ category +'"]').offset().top.toFixed(0);

				setTimeout(function(){

					enquire.register("screen and (min-width:  61.9em)", {
						match : function(){
							$('body, html').stop(true, true).animate({ scrollTop: position - 20 }, 600, 'easeOutCubic');
						}
					}, true);

					enquire.register("screen and (max-width:  61.9em)", {
						match : function(){
							$('.faq-container').removeClass('faq-container--active');
							$('body, html').stop(true, true).animate({ scrollTop: position - 60 }, 600, 'easeOutCubic');
						}
					}, true);

					/*
					hash_link.addClass('active')
					.parents('.faq-navigation__item')
					.addClass('active expanded')
					.find('.faq-navigation__dropdown')
					.slideDown(250, 'easeOutCubic');
					*/

				}, 100);

			}

			$('.faq-group').addClass('faq-group--unactive');
			$('.faq-group[data-group="'+ category +'"]').removeClass('faq-group--unactive');


			$('.faq-navigation__item').removeClass('active');
			$('.faq-navigation__item[data-group="'+ category +'"]').addClass('active');

		}, $faqSearhDelay*1.5);

	}


	$('.js-toggle__faq-all').on('click', function(){

		$('.faq-group').removeClass('faq-group--unactive');
		$faq_context = $('.accordion-list--faq .accordion-list__item');

		$('.faq-navigation__item').removeClass('active expanded');
		$('.faq-navigation__dropdown').stop(true, true).slideUp(250, 'easeOutCubic');

		$(this).parents('.faq-navigation__item').addClass('active');

		$('.js-navigation__section').removeClass('active');
		$('.faq-container').removeClass('faq-container--active');

		$('html, body').stop(true, true).animate({ scrollTop: $('.main-content').offset().top }, 1000, 'easeOutCubic', function(){
			if( window.history.pushState ){
				var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
				window.history.pushState({ path: newurl }, '', newurl);
			}
		});
	});


	$('.js-toggle__faq-group:not(.faq-navigation__button--all)').on('click', function(){

		var master = $(this).parents('.faq-navigation__item'),
			faq_group = $(master).data('group');

		$('.faq-group').removeClass('el-hide').addClass('faq-group--unactive');
		$('[data-group="'+ faq_group +'"]').removeClass('faq-group--unactive');

		$('.faq-navigation__item').not($(master)).removeClass('active expanded');
		$('.faq-navigation__dropdown').not($('.faq-navigation__dropdown', master)).stop(true, true).slideUp(250, 'easeOutCubic');

		enquire.register("screen and (min-width:  61.9em)", {
			match : function(){
				$('body, html').stop(true, true).animate({ scrollTop: $('[data-group="'+ faq_group +'"]').offset().top - 30 }, 600, 'easeOutCubic');
			}
		}, true);

		enquire.register("screen and (max-width:  61.9em)", {
			match : function(){
				if( $('html').hasClass('show-fixed-header') ){
					$('body, html').stop(true, true).animate({ scrollTop: $('[data-group="'+ faq_group +'"]').offset().top - 160 }, 600, 'easeOutCubic');
				} else {
					$('body, html').stop(true, true).animate({ scrollTop: $('[data-group="'+ faq_group +'"]').offset().top - 260 }, 600, 'easeOutCubic');
				}
			}
		}, true);

		$(master).addClass('active');

		$faq_context = $('.accordion-list--faq .accordion-list__item');

	});


	$('.js-toggle__faq-item').on('click', function(){

		var master = $(this).parents('.faq-navigation__item'),
			faq_group = $(master).data('group');

		$('.faq-group').addClass('faq-group--unactive');
		$('[data-group="'+ faq_group +'"]').removeClass('faq-group--unactive');

		$('.faq-navigation__item').not($(master)).removeClass('active expanded');

		if( !$(master).hasClass('active') ){
			$('body, html').stop(true, true).animate({ scrollTop: $('[data-group="'+ faq_group +'"]').offset().top - 30 }, 600, 'easeOutCubic');
		}

		$(master).toggleClass('active expanded');

		$('.faq-navigation__dropdown', master).stop(true, true).slideToggle(250, 'easeOutCubic');
		$('.faq-navigation__dropdown').not($('.faq-navigation__dropdown', master)).stop(true, true).slideUp(250, 'easeOutCubic');

		$faq_context = $('.accordion-list--faq .accordion-list__item:not(.faq-group--unactive .accordion-list--faq .accordion-list__item)');

	});


	$('.js-navigation__section').on('click', function(){

		$('.js-navigation__section').removeClass('active');
		$(this).addClass('active');

		if( $($(this).attr('href')).length > 0 ){
			var position = $($(this).attr('href')).offset().top.toFixed(0);

			enquire.register("screen and (min-width:  61.9em)", {
				match : function(){
					$('body, html').stop(true, true).animate({ scrollTop: position - 20 }, 600, 'easeOutCubic');
				}
			}, true);

			enquire.register("screen and (max-width:  61.9em)", {
				match : function(){
					$('.faq-container').removeClass('faq-container--active');
					$('body, html').stop(true, true).animate({ scrollTop: position - 60 }, 600, 'easeOutCubic');
				}
			}, true);
		}

	});


	$('.js-faq__results').on('change', function(){

		var position = $('.faq-container').offset().top;

		if( this.value == 'all' ){

			$('.faq-group').removeClass('el-hide');

			$('#faq_results_count').html( $('.accordion-list__item--active').length );

		} else {

			$('.faq-group').addClass('el-hide');
			$('.faq-group[data-group="'+ this.value +'"]').removeClass('el-hide');

			$('#faq_results_count').html( $('.faq-group[data-group="'+ this.value +'"] .accordion-list__item--active').length );

		}

		enquire.register("screen and (min-width:  61.9em)", {
			match : function(){
				$('body, html').stop(true, true).animate({ scrollTop: position - 19 }, 600, 'easeOutCubic');
			}
		}, true);

		enquire.register("screen and (max-width:  61.9em)", {
			match : function(){
				$('.faq-container').removeClass('faq-container--active');
				$('body, html').stop(true, true).animate({ scrollTop: position - 60 }, 600, 'easeOutCubic');
			}
		}, true);

	});

	setTimeout(function(){
		if( $('.js-faq__results:checked').val() != 'all' ){
			$('.js-faq__results:checked').trigger('change');
		}
	}, $faqSearhDelay + 50);


	$(window).on('scroll touchmove', function(){

		if( $('.faq-container').hasClass('faq-container--filtered') ){
			return;
		}

		var currentTop = $(window).scrollTop(),
			groups = $('.faq-group');

		groups.each(function(){
			var elemTop 	= $(this).offset().top,
				elemBottom 	= elemTop + $(this).height();

			if( currentTop + 84 >= elemTop && currentTop <= elemBottom ){

				var group = $(this).data('group');

				$('.faq-navigation__item').removeClass('active');
				$('.faq-navigation__item[data-group="'+ group +'"]').addClass('active');

				clearTimeout(faqTimer);

				faqTimer = setTimeout(function(){
					if( window.history.pushState ){
						var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?category='+ group +'';
						window.history.pushState({ path: newurl }, '', newurl);
					}
				}, 150);

			} else if( currentTop < $('.faq-container__content').offset().top - 150 ){

				$('.faq-navigation__item').removeClass('active');
				$('.js-toggle__faq-all').parents('.faq-navigation__item').addClass('active');

			}
		});

	});


	if( navigator.userAgent.indexOf('MSIE')!==-1 || navigator.appVersion.indexOf('Trident/') > -1 ){

		$(window).on('scroll', function(){

			var currentIETop = $(window).scrollTop();

			if( currentIETop > $('.faq-container').offset().top && currentIETop < $('.faq-container').offset().top + $('.faq-container').height() - ($('.faq-navigation').height()/1.5) ){

				$('.faq-container__aside').addClass('faq-container__aside--fixed');

			} else {
				$('.faq-container__aside').removeClass('faq-container__aside--fixed');
			}

		});

	}

}

function FixedFAQNavigation(){
	if( scrollFAQPos >= $('.faq-container').position().top && scrollFAQPos < $('.faq-container').position().top + $('.faq-container').innerHeight() - 170 ){
		$('.faq-container').addClass('faq-container--fixed');
	} else {
		$('.faq-container').removeClass('faq-container--fixed');
	}
}

enquire.register("screen and (max-width:  61.9em)", {
	match : function(){

		if( $('.faq-container').length > 0 ){
			$(window).on('scroll resize', function(){
				scrollFAQPos = $(window).scrollTop();
				FixedFAQNavigation();
			}).trigger('scroll');

		}

	}
}, true);

$('.js-button__navigation').on('click', function(){
	$('.faq-container').toggleClass('faq-container--active');
});

$(document.body).on('click', function(e){
	if( $(e.target).closest($('.faq-container__aside')).length == 0 ){
		$('.faq-container').removeClass('faq-container--active');
	}
});


// Custom phone
if( $('.custom-phone__select').length > 0 ){

	function formatData(data){

		var text = data.text,
			code = data.code;

		var telephone_markup = "<div class='custom-phone__template'>\
			<p class='custom-phone__template-name'>"+ text +"</p>\
			<p class='custom-phone__template-code'>"+ code +"</p>\
		</div>";

		return telephone_markup;
	}

	function formatDataSelection(data){
		var code = data.code;
		return "<span>"+ code +"</span>";
	}

	function selectInit(data, e) {
		$('select', e).select2({
			data: data.phoneCodes,
			templateResult: formatData,
			templateSelection: formatDataSelection,
			escapeMarkup: function(telephone_markup){
				return telephone_markup;
			},
			dropdownParent: $(e),
			containerCssClass: $(e).prop('class'),
			minimumResultsForSearch: $('select', e).data('search'),
			language: language,
			width: '100%'

		}).on('select2:open', function(event){
			$('.select2-dropdown', e).css('min-width', $(e).parents('.custom-phone').innerWidth() + 'px' );
			$('.select2-search__field').attr('placeholder', 'Търси ...').focus();

		}).on('select2:select', function(event){
			$('select', e).trigger('input');
			$('.select2-selection__rendered', e).addClass('select2-selection__rendered--selected').attr('title', '');

		});

		$('.select2-selection__rendered', e).attr('title', '');
	}

	$('.custom-phone__select').each(function(i, e){
		var phoneInput = $(this).parents('.custom-phone').find('.custom-phone__input');
		var dataUrl = $(this).data('ajax-url')

		$(phoneInput).on('focus',function() {
			$.ajax({
				url: dataUrl,
				dataType: 'json',
				async: false,
				success: function (data) {
					selectInit(data, e);
				}
			});
		});
	});

	var select2Timer;

	window.addEventListener(orientationEvent, (function(){

		clearTimeout(select2Timer);

		select2Timer = setTimeout(function(){
			$('.select2-dropdown').css('min-width', $('.select2-dropdown').parents('.custom-phone').innerWidth() + 'px' );
		}, 150);

	}), false);
}

$(document.body).on('focus', '.custom-phone__input', function(){
	$(this).attr('placeholder', '000 000 000');
	$(this).parents('.custom-phone').addClass('custom-phone--active');
});


// Board management
if( $('.board-slider').length > 0 ){

	function setBoardInfo(){
		$('#board_name').html( $('.slick-center .board-slider__button').data('name') );
		$('#board_position').html( $('.slick-center .board-slider__button').data('position') );

		$('.board-popup__header').html( $('.slick-center .board-slider__button').data('name') );
		$('.board-popup__body').scrollTop(0).html( $('.slick-center .sr').html() );
	}

	var board_slider = $('.board-slider').on('init', function(){

		setTimeout(function(){
			setBoardInfo();
		}, 1000);

	}).on('afterChange', function(){

		setBoardInfo();

	}).slick({
		speed: 650,
		touchThreshold: 100,
		dots: false,
		arrows: false,
		centerMode: true,
		focusOnSelect: true,
		slidesToShow: 1,
		centerPadding: '33.3333%',
		responsive:[
			{
				breakpoint: 1200,
				settings:{
					slidesToShow: 1,
					centerPadding: '26%'
				}
			},
			{
				breakpoint: 607,
				settings: {
					speed: 500,
					arrows: true,
					slidesToShow: 1,
					centerPadding: '20%'
				}
			},
			{
				breakpoint: 560,
				settings: {
					speed: 500,
					arrows: true,
					slidesToShow: 1,
					centerPadding: '15%'
				}
			}
		]
	});

	$(document.body).on('click', '.js-board-slider-prev', function(){
		board_slider.slick('slickPrev');
	}).on('click', '.js-board-slider-next', function(){
		board_slider.slick('slickNext');
	});

	$(document.body).on('click', '.js-board__button, .js-board-slider__popup', function(){
		$('html').toggleClass('board-active');
	}).on('click', '.board-popup__close', function(){
		$('html').removeClass('board-active');
	});
}


// Presentation
if( $('.presentation-carousel').length > 0 ){

	$('.presentation-carousel').each(function(i, item){

		if(!isTouch){
			var lFollowX = 0,
				lFollowY = 0,
				x = 0,
				y = 0,
				friction = 1 / 15;

			$(document.body).on('mouseenter', '.presentation-carousel__item', function(e){

				var lFollowX = 0,
					lFollowY = 0,
					x = 0,
					y = 0;

			}).on('mousemove', '.presentation-carousel__item', function(e){

				var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
				var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
				lFollowX = (30 * lMouseX) / 100;
				lFollowY = (30 * lMouseY) / 100;

				x += (lFollowX - x) * friction;
				y += (lFollowY - y) * friction;

				translate = 'translate3d(' + x + 'px, ' + y + 'px, 0px)';

				$('.presentation-carousel__image', e.currentTarget).css({
					'-webit-transform': translate,
					'-moz-transform': translate,
					'transform': translate
				});

			});
		}

		$(item).slick({
			speed: 450,
			arrows: false,
			dots: true,
			adaptiveHeight: true,
			touchThreshold: 100,
			slidesToShow: $(item).data('slides'),
			slidesToScroll: $(item).data('slides'),
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: $(item).data('slides') - 1,
						slidesToScroll: $(item).data('slides') - 1
					}
				},
				,
				{
					breakpoint: 560,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		})
	});

}


// Quotes slider
if( $('.quotes-slider').length > 0 ){
	$('.quotes-slider').each(function(i, e){
		$(e).slick({
			speed: 450,
			arrows: true,
			dots: true,
			adaptiveHeight: true,
			touchThreshold: 100
		});
	})
}


// Compare
if( $('.compare-header').length > 0 ){

	$('.compare-popup').on('scroll touchmove', function(){

		var scrollTop = $('.compare-popup').scrollTop();

		if( scrollTop > 150 ){
			$('html').addClass('compare-header-fixed');
		} else {
			$('html').removeClass('compare-header-fixed');
		}

		if( scrollTop > 250 ){
			$('html').addClass('compare-header-transition');
		} else {
			$('html').removeClass('compare-header-transition');
		}

		if( scrollTop > 450 ){
			$('html').addClass('compare-header-show');
		} else {
			$('html').removeClass('compare-header-show');
		}

	}).trigger('scroll');
}


$(document.body).on('click', '.js-change-list__type:not(.active)', function(){
	$('.js-change-list__type').removeClass('active');
	$(this).addClass('active');

	$('.scrollable-tabs__tab').removeClass('active');
	$('.scrollable-tabs__tab[data-tab="'+ $(this).data('type') +'"]').addClass('active');

	if (typeof(Event) === 'function'){
		window.dispatchEvent(new Event('resize'));
	} else {
		var evt = window.document.createEvent('UIEvents');
		evt.initUIEvent('resize', true, false, window, 0);
		window.dispatchEvent(evt);
	}
});


// Custom file upload
function formatBytes(bytes,decimals){
	if(bytes == 0) return '0 Bytes';
	var k = 1024,
		dm = decimals <= 0 ? 0 : decimals || 2,
		sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

$(document).on('change', '.custom-file :file', function(e){

	$('.custom-file__error', master_holder).remove();

	var master = $(this).parents('.custom-file'),
		master_holder = master.parents('.custom-file__holder'),
		input = $(':file', master),
		text = $(':text', master),
		extension = input.val().split('.').pop().toLowerCase(),
		numFiles = input.get(0).files ? input.get(0).files.length : 1,
		label = input.val().replace(/\\/g, '/').replace(/.*\//, ''),
		allowedExtensions = [],
		allowedSize = $(input).data('size'),
		allowedLength = $(input).data('length');

	allowedExtensions.push( $(input).data('extensions').toLowerCase().split(',') );

	//input.val('');

	if( $.inArray(extension, allowedExtensions[0] ) == -1 ){

		$('<p class="custom-file__error">'+ input.data('extensions-error') +': '+ allowedExtensions[0] +'.' +'</p>').insertBefore($('.file-list--template', master_holder));

		input.val('');
		//text.val('');
		return;

	} else {

		if( this.files.length > allowedLength ){
			$('<p class="custom-file__error">'+ input.data('length-error') +' '+ allowedLength +'.</p>').insertBefore($('.file-list--template', master_holder));

			input.val('');
			//text.val('');
			return;
		};

		for( var i = 0; i < this.files.length; i++ ){
			if( this.files[i].size / (1024 * 1024) >= allowedSize ){
				$('<p class="custom-file__error">'+ input.data('size-error') +' '+ input.data('size') +'MB.' +'</p>').insertBefore($('.file-list--template', master_holder));

				input.val('');
				//text.val('');
				return;
			}
		};


		$('.file-list--template', master_holder).html('');

		for( var i = 0; i < this.files.length; i++ ){

			if( this.files[i].size / (1024 * 1024) <= allowedSize ){

				var file_name = this.files[i].name,
					file_size = this.files[i].size,
					file_type = this.files[i].name.split('.').pop().toLowerCase();

				input.trigger('fileselect', [
					numFiles,
					label
				]);

				if( $(input).attr('multiple') == undefined ){

					$('<li class="file-list__item">\
						<div class="file">\
							<div class="file__type"><i class="i-file-default i-'+ file_type +'"></i></div>\
							<div class="file__text">\
								<strong class="file__text-name">'+ file_name +'</strong>\
								<span class="file__text-info file__text-info--type">'+ file_type +'</span>\
								<span class="file__text-info file__text-info--size">'+ formatBytes(file_size) +'</span>\
							</div>\
							<button type="button" class="file-list__remove">'+ $(input).data('remove-files') +'</button>\
						</div>\
					</li>').appendTo($('.file-list--template', master_holder));

				} else {

					$('<li class="file-list__item">\
						<div class="file">\
							<div class="file__type"><i class="i-file-default i-'+ file_type +'"></i></div>\
							<div class="file__text">\
								<strong class="file__text-name">'+ file_name +'</strong>\
								<span class="file__text-info file__text-info--type">'+ file_type +'</span>\
								<span class="file__text-info file__text-info--size">'+ formatBytes(file_size) +'</span>\
							</div>\
						</div>\
					</li>').appendTo($('.file-list--template', master_holder));

				}

			}

		}

		if( $(input).attr('multiple') != undefined ){
			$('<li class="text-right"><button type="button" class="file-list__remove file-list__remove--all">'+ $(input).data('remove-files') +'</button></li>').appendTo($('.file-list--template', master_holder));
		}

	}
});

/*
$(document).on('fileselect', '.custom-file :file', function(event, numFiles, label){
	var custom = $(this).parents('.custom-file'),
		input = $(':text', custom),
		log = numFiles > 1 ? numFiles + ' files selected' : label

	if( input.length > 0 ){
		input.val(log);
	}
});
*/

$(document).on('click', '.file-list__remove:not(.file-list__remove--all)', function(){
	var master = $(this).parents('.custom-file__holder');
	$(this).parents('.file-list__item').remove();
	$('input', master).val('');
});

$(document).on('click', '.file-list__remove--all', function(){
	var master = $(this).parents('.custom-file__holder');
	$('.file-list--template', master).html('');
	$('input', master).val('');
});


// notification
function notificationAppend(notificationText, type) {
    if ( type !== 'success' && type !== 'error' ) {
        type = 'info';
    }

    if ( !$('.js-notification-box').length ) {
        $('.site').append('<ul class="notification-box js-notification-box"></ul>')
    }

    var notificationElement = $(
        '<li class="notification notification--'+ type +' js-notification">' +
            '<p class="notification__text js-notification__text">' + notificationText + '</p>' +
			'<button class="notification__close js-notification__close">' +
				'<i class="i-multiply"></i>' +
			'</button>' +
        '</li>');

    $('.js-notification-box').append(notificationElement);

    var notificationTimeOut = setTimeout(function() {
        notificationRemove(notificationElement);
    }, 5000);
    notificationElement.data('notificationTimeOut', notificationTimeOut);
}

function notificationRemove(thisNotification) {
    clearTimeout(thisNotification.data('notificationTimeOut'));
    thisNotification.addClass('is-closed');
    thisNotification.on('webkitAnimationEnd oanimationend msAnimationEnd animationend', function() {
        thisNotification.remove();
    });
}

// Notification action
$(document).on('mouseenter touchstart', '.js-notification', function() {
    clearTimeout($(this).data('notificationTimeOut'));
});
$(document).on('mouseleave touchend', '.js-notification', function() {
    var thisNotification = $(this);
    clearTimeout(thisNotification.data('notificationTimeOut'));
    var notificationTimeOut = setTimeout(function() {
        notificationRemove(thisNotification);
    }, 5000);
    $(this).data('notificationTimeOut', notificationTimeOut);
});

$(document).on('click', '.js-notification__close, .notification__button',function() {
    var thisNotification = $(this).parents('.js-notification');
    notificationRemove(thisNotification);
});


// Tabs
function changeTab(thisTab){
    var tabsContainer = $('.js-tabs__link[data-tab=' + thisTab + ']').parents('.js-tabs');
    $('.js-tabs__link', tabsContainer).removeClass('is-active');
    $('.js-tabs__link[data-tab=' + thisTab + ']', tabsContainer).addClass('is-active');

    $('.js-tabs__content', tabsContainer).removeClass('is-active');
	$('.js-tabs__content[data-tab=' + thisTab + ']', tabsContainer).addClass('is-active');
}

function changeTabHash() {
	var tabHash = window.location.hash;

    if ( !tabHash == '#' && !tabHash == '' ) {
        changeTab(tabHash.substring(1));
    }
}

$(window).on('hashchange', function () {
    changeTabHash();
});

if ( window.location.hash && window.location.hash != '#' ) {
    changeTabHash();
}

$(document).on('click', '.js-tabs__link', function(e){
    if (!$(this).hasClass('is-active')) {
		var thisTab = $(this).data('tab');
		changeTab(thisTab);

		var position = $('#' + thisTab).offset().top.toFixed(0) - 64;
		$('body, html').stop(true, true).animate({ scrollTop: position }, 600, 'easeOutCubic');
    } else {
		e.preventDefault();
	}
});

function cardSlider(sliderContainer) {
    var slider = $('.js-card-slider__carousel', sliderContainer);
    var slides = slider.children().length;

    if ( slides > 1 ) {
        slider.slick({
			dots: true,
            vertical: true,
            verticalSwiping: true,
            infinite: false,
			lazyLoad: 'ondemand',
			touchThreshold: 100
        });
    }
}

if ( $('.js-card-slider').length ) {
    $('.js-card-slider').each(function() {
        cardSlider($(this));
    });
}

function cardBoxSlider(sliderContainer) {
    var cardBoxSliderCarousel = $('.js-card-box-slider__carousel', sliderContainer);
    var cardBoxSliderControls = $('.js-card-box-slider__controls', sliderContainer);
    cardBoxSliderCarousel.slick({
        infinite: false,
        variableWidth: true,
        outerEdgeLimit: true, //new option from modified version
		appendArrows: cardBoxSliderControls,
		touchThreshold: 100,
        responsive: [
			{
                breakpoint: 767,
                settings: {
					arrows: false,
					dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
					arrows: false,
					dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                }
            }
      ]
    });
}

if ( $('.js-card-box-slider').length ) {
    $('.js-card-box-slider').each(function() {
        cardBoxSlider($(this));
    });
}

function minimize(toggle) {
    var minimizeToggle = toggle;
    var minimize = minimizeToggle.parents('.js-minimize');
    var minimizeSection = $('.js-minimize__section', minimize);
    minimize.toggleClass('is-open');
    minimizeSection.stop().slideToggle(250, 'easeOutCubic', function(){
		minimize.removeClass('is-closed');
	});
}


$(document).on('click', '.js-minimize__toggle', function() {
    minimize($(this));
});


// Wrap tables
if( $('.text table').length ){
	$('.text table').each(function(i, e){

		if( !$(e).parents('div').hasClass('scrollable-content') ){
			$(e).wrapAll('<div class="scrollable-content" />');
		}

	});
}


// Wrap youtube iframes
$('iframe:not(.video-embed iframe):not(.mce-preview-object iframe)').filter(function(){
	return this.src.match(/(vimeo|youtube)./i);
}).wrap('<div class="video-embed" />');


// Popup window
var bindPopup = function(popup){
	$('#' + popup).attr('aria-hidden', false);
	$('.tippy').trigger('click');
}

var unbindPopup = function(popup){
	$('#' + popup).attr('aria-hidden', true);
}

var removePopup = function(popup){
	$('#' + popup).remove();
}

$(document).on('click', '.popup-close:not(.popup-remove)', function(){
	$(this).parents('.popup').attr('aria-hidden', true);
}).on('click', '.popup-remove', function(){
	$(this).parents('.popup').remove();
}).on('click', '.popup-offer__close', function(){
	$(this).parents('.popup-offer').removeClass('popup-offer--visible popup-offer--bottom');
}).on('keydown', function(e){
	if( e.which === 27 ){
		$('.popup').attr('aria-hidden', true);
		$('.popup-offer').removeClass('popup-offer--visible popup-offer--bottom');
	}
});

var bindOfferPopup = function(popup){
	var st = $(window).scrollTop();
		/*mh = $('#' + popup + ' .popup-offer__content').innerHeight(),
		mo = 175;*/

	$('#' + popup + ' .popup-offer__content').css('top', st + 'px').promise().done(function(){
		$('#' + popup).removeClass('popup-offer--bottom').addClass('popup-offer--visible');
	});

	/*
	if( st + mo <= mh ){

	} else {
		//$('#' + popup).addClass('popup-offer--visible popup-offer--bottom');
	}
	*/

	$('.aside-offer').removeClass('aside-offer--expanded');
}





$('.text a:not(.button):not(a:has(img)), .useful-links a:not(a:has(img)):not(.button):not(a[href^="tel:"]):not(a[href^="mailto:"]):not(a[href^="javascript"]):not(a[href^=""]):not(.apps-list a), .cards-table__td a:not(.button):not(a:has(img)), .packs-list__row-item__inner p a:not(.button):not(a:has(img)), .accordion-list__body a:not(.button):not(a:has(img))').each(function(){
	if( location.hostname != this.hostname || !this.hostname.length ){
		$('<i class="i-external-link-alt external-icon" />').appendTo(this);
		$(this).attr('target', '_blank');
	}
});



// Scroll to target
var scrollToTarget = function(scroll_target){
	$('html, body').stop(true, true).animate({ scrollTop: $(''+ scroll_target +'').offset().top }, 1000, 'easeOutCubic');
};


// Tilt effects
if( isTouch == false ){
	$('.selected-slider__item-wrap, .similar-slider__item-wrap, .vendors-list__inner, .services-list__inner, .fast-links__item, .details-list__item').tilt({
		glare: true,
		maxGlare: .8,
		scale: 1,
		maxTilt: 7,
		speed: 1000
	});

	$('.card-slider__item').tilt({
		glare: false,
		scale: 1,
		maxTilt: 7,
		speed: 1000
	});

	$('.promo-list__inner').tilt({
		glare: true,
		maxGlare: .8,
		scale: 1,
		maxTilt: 5,
		speed: 1000
	});

	$('.accounts-list__item').tilt({
		glare: false,
		scale: 1,
		maxTilt: 6,
		speed: 1000,
		perspective: 300
	});
}

function showPreloader(){
	$('<div class="ajax-preloader">\
		<div class="ajax-preloader__wrapper">\
			<svg width="68" height="67" viewBox="0 0 68 67" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="el_1SyW355nS">\
				<g id="el_3XyNis4omG" fill-rule="evenodd">\
					<g id="el_2s7FhklBho" fill-rule="nonzero">\
						<g id="el_f2_CXBZQ5j_an_-ayVmOtSm" data-animator-group="true" data-animator-type="0"><path d="M42.6042182,8.74504082 L67.5394021,33.4675076 L61.6305908,39.3290484 L36.6920303,14.6116464 L42.6042182,8.74504082 Z M42.7257709,13.2644374 L41.2418151,14.7298226 L61.5124145,34.8197813 L62.9913056,33.3493313 L42.7257709,13.2644374 Z" id="el_f2_CXBZQ5j"/></g>\
						<g id="el_c8PzyT0Lu3_an_D2UVIBEeU" data-animator-group="true" data-animator-type="0"><path d="M33.7815187,-1.10134124e-13 L39.69033,5.86154087 L14.7551461,30.5789429 L8.84127003,24.7174021 L33.7815187,-1.10134124e-13 Z M33.6582778,4.51770835 L13.3910548,24.6009141 L14.8716341,26.064611 L35.1405453,5.98309356 L33.6582778,4.51770835 Z" id="el_c8PzyT0Lu3"/></g>\
						<g id="el_WYgrfgvlwF_an_nEQOz4D3N" data-animator-group="true" data-animator-type="0"><path d="M52.7943854,36.3594487 L58.7065733,42.2209895 L33.7747658,66.9367034 L27.855825,61.0751625 L52.7943854,36.3594487 Z M52.6778974,40.8754688 L32.4106744,60.9569863 L33.8912538,62.4240597 L54.1584767,42.340854 L52.6778974,40.8754688 Z" id="el_WYgrfgvlwF"/></g>\
						<g id="el_65AU8nP9-w_an_2ReB4fRy7" data-animator-group="true" data-animator-type="0"><path d="M5.91218783,27.6042785 L30.8507482,52.3199923 L24.9402487,58.1882861 L-1.24344979e-14,33.4691958 L5.91218783,27.6042785 Z M6.03036406,32.1152339 L4.55316121,33.5890603 L24.8237607,53.6638248 L26.2942106,52.2051925 L6.03036406,32.1152339 Z" id="el_65AU8nP9-w"/></g>\
						<polygon id="el_Uo0hAEOs1l" points="30.0083206 21.0235508 37.5361462 21.0235508 37.5361462 45.9199055 30.0083206 45.9199055"/>\
					</g>\
				</g>\
			</svg>\
		</div>\
	</div>').appendTo('body').fadeIn();
};

function hidePreloader(){
	$('.ajax-preloader').fadeOut().promise().done(function(){
		$('.ajax-preloader').remove();
	});
};


moment.locale(document.documentElement.lang.substring(0, 2));