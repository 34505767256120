var language = document.documentElement.lang.substring(0, 2);  

// Validate forms
Parsley.setLocale(language);
$('form:not([data-skip-validator="true"])').parsley();

// Calculators
ReplaceNumberWithSpace = function(input){
    return (input.toString()).replace(/^([-+]?)(0?)(\d+)(.?)(\d+)$/g, function(match, sign, zeros, before, decimal, after){
    
        var reverseString = function(string) { return string.split('').reverse().join(''); };
        
        var insertCommas = function(string) { 
            var reversed = reverseString(string);
            var reversedWithCommas = reversed.match(/.{1,3}/g).join(' ');
            return reverseString(reversedWithCommas);
        };
        return sign + (decimal ? insertCommas(before) + decimal + after : insertCommas(before + after));
    });
}

setInputSize = function(input){

    switch( input.val().replace(/[^0-9]/g, '').length ){
        case 0:
            $(input).attr('data-size', 1);
        break;
        case 1:
            $(input).attr('data-size', 1);
        break;
        case 2:
            $(input).attr('data-size', 2);
        break;
        case 3:
            $(input).attr('data-size', 3);
        break;
        case 4:
            $(input).attr('data-size', 4);
        break;
        case 5:
            $(input).attr('data-size', 5);
        break;
        case 6:
            $(input).attr('data-size', 6);
        break;
        case 7:
            $(input).attr('data-size', 7);
        break;
        case 8:
            $(input).attr('data-size', 8);
        break;
        case 9:
            $(input).attr('data-size', 9);
        break;
        case 10:
            $(input).attr('data-size', 10);
        break;
    }
}


// Change currency
if( $('.js-calculator__currency').length > 0 ){

    var currency = $('.js-calculator__currency:checked').val().toLowerCase();
    
    $('.js-calculator__currency').on('change', function(){

        currency = this.value.toLowerCase();

        calculator_amount_instance.update({
            min: currencies[currency].amount_min,
            max: currencies[currency].amount_max,
            from: currencies[currency].amount_from,
            step: currencies[currency].amount_step
        });

        calculator_period_instance.update({
            min: currencies[currency].period_min,
            max: currencies[currency].period_max,
            from: currencies[currency].period_from,
            step: currencies[currency].period_step
        });

        $('.js-currency').html(currency);
    });
}

// Calculator amount
if( $('.calculator-amount').length > 0 ){
    
    var $amount_master = $('.calculator-amount'),
        $amount_input = $('.calculator-box__input', $amount_master),
        $amount_input_id = $amount_input.prop('id'),
        $amount_output = $('.calculator-box__output', $amount_master),
        $amount_id = Math.floor((Math.random() * 1000) + 1),
        $amount_placeholder = $('.calculator-box__placeholder', $amount_master).html();

    var $calculator_amount = $('#'+ $amount_input_id +'').ionRangeSlider({
        min: currencies[currency].amount_min,
        max: currencies[currency].amount_max,
        from: currencies[currency].amount_from,
        step: currencies[currency].amount_step,
        hide_min_max: true,
        hide_from_to: true,
        skin: 'fibank',
        onStart: function (data){

            if( $amount_master.is('.disabled') ){
                setTimeout(function(){
                    var calculator_amount_instance = $calculator_amount.data('ionRangeSlider');
                    calculator_amount_instance.update({
                        from: 0
                    });
                }, 2);
            }
            
            $amount_output.html('<label for="'+ $amount_id +'" class="sr">'+ $amount_placeholder +'</label><input id="'+ $amount_id +'" pattern="[0-9]*" title="" type="text" class="calculator-box__input-element js-calculator__value" value="'+ ReplaceNumberWithSpace(data.from) +'" maxlength="'+ currencies[currency].amount_max.toString().length + 1 +'" /> <span class="calculator-box__input-postfix js-currency text-uppercase">'+ currency +'</span>');

            setInputSize($('.js-calculator__value', $amount_master));
            
            $('.amount-output').html(ReplaceNumberWithSpace(data.from) + ' <span class="js-currency text-uppercase">'+ currency +'</span>');

            $('[name="amount"]').val(data.from);

        },
        onChange: function (data){

            $amount_master.removeClass('disabled');

            $('.js-calculator__value', $amount_master).val(ReplaceNumberWithSpace(data.from));

            setInputSize($('.js-calculator__value', $amount_master));

        },
        onFinish: function(data){

            $('.amount-output').html(ReplaceNumberWithSpace(data.from) + ' <span class="js-currency text-uppercase">'+ currency +'</span>');

            $('[name="amount"]').val(data.from);

            if( data.from === 0 || calculator_period_instance.old_from === 0 ){
                $('.js-button__form').prop('disabled', true);
            } else {
                $('.js-button__form').prop('disabled', false);
            }

            // For the demo
            // $('.js-button__form').prop('disabled', true);
            // setTimeout(function(){
            //     $('.js-button__form').prop('disabled', false);
            // }, 2000);

        },
        onUpdate: function(data){
            
            $('.js-calculator__value', $amount_master).val(ReplaceNumberWithSpace(data.from));

            setInputSize($('.js-calculator__value', $amount_master));

            $('.amount-output').html(ReplaceNumberWithSpace(data.from) + ' <span class="js-currency text-uppercase">'+ currency +'</span>');

            $('[name="amount"]').val(data.from);

            if( data.from === 0 || calculator_period_instance.old_from === 0 ){
                $('.js-button__form').prop('disabled', true);
            } else {
                $('.js-button__form').prop('disabled', false);
            }
        }
    });

    
    var calculator_amount_instance = $calculator_amount.data('ionRangeSlider');

    $('.js-calculator__value', $amount_master).on('focus', function(){

        $(this)[0].setSelectionRange(99999, 99999);

    }).on('input change', function(){

        this.value = this.value.replace(/[^0-9]/g, '');

        if( $(this).val() >= currencies[currency].amount_max ){
            this.value = ReplaceNumberWithSpace(currencies[currency].amount_max);
        } else {
            this.value = ReplaceNumberWithSpace(this.value);
        }

        setInputSize($('.js-calculator__value', $amount_master));

    }).on('blur', function(){

        if( $(this).val().replace(/ /g , '') > currencies[currency].amount_max ){

            $(this).val( ReplaceNumberWithSpace(currencies[currency].amount_max) );
            
            calculator_amount_instance.update({
                from: currencies[currency].amount_max
            });

        } else {
            calculator_amount_instance.update({
                from: $(this).val().replace(/ /g , '')
            });
        }

        $('.amount-output').html(ReplaceNumberWithSpace(this.value) + ' <span class="js-currency text-uppercase">'+ currency +'</span>');

    }).on('keypress', function(e){
        
        if(e.which === 13){               
            
            if( $(this).val().replace(/ /g , '') > currencies[currency].max ){

                $(this).val( ReplaceNumberWithSpace(currencies[currency].max) );
                
                calculator_amount_instance.update({
                    from: currencies[currency].max
                });

            } else {
                calculator_amount_instance.update({
                    from: $(this).val().replace(/ /g , '')
                });
            }

            $(this).blur();

            $('.amount-output').html(ReplaceNumberWithSpace(this.value) +' <span class="js-currency text-uppercase">'+ currency +'</span>');
        }

    });

    $('.calculator-box__button', $amount_master ).on('click', function(){
        
        $amount_master.addClass('disabled');

        $('.js-calculator__value', $amount_master).val(0);
        
        calculator_amount_instance.update({
            from: 0
        });

        $('.amount-output').html('-');
        $('[name="amount"]').val(0);
    });

}


// Calculator period
if( $('.calculator-period').length > 0 ){
    
    var $period_master = $('.calculator-period'),
        $period_input = $('.calculator-box__input', $period_master),
        $period_input_id = $period_input.prop('id'),
        $period_output = $('.calculator-box__output', $period_master),
        $period_id_1 = Math.floor((Math.random() * 1000) + 1),
        $period_id_2 = Math.floor((Math.random() * 1000) + 1);

    var $calculator_period = $('#'+ $period_input_id +'').ionRangeSlider({
        min: currencies[currency].period_min,
        max: currencies[currency].period_max,
        from: currencies[currency].period_from,
        step: currencies[currency].period_step,
        hide_min_max: true,
        hide_from_to: true,
        skin: 'fibank',
        onStart: function (data){

            if( $period_master.is('.disabled') ){
                setTimeout(function(){
                    var calculator_period_instance = $calculator_period.data('ionRangeSlider');
                    calculator_period_instance.update({
                        from: 0
                    });
                }, 2);
            }
            
            $period_output.html('<label for="'+ $period_id_1 +'" class="sr">год.</label><input id="'+ $period_id_1 +'" pattern="[0-9]*" title="" type="text" class="calculator-box__input-element js-calculator__years" value="'+ (data.from / 12 | 0) +'" maxlength="3" />' + ' <span class="calculator-box__input-postfix">год. и</span> ' + '<label for="'+ $period_id_2 +'" class="sr">мес.</label><input id="'+ $period_id_2 +'" pattern="[0-9]*" title="" type="text" class="calculator-box__input-element js-calculator__months" value="'+ data.from % 12 +'" maxlength="2" /> <span class="calculator-box__input-postfix">мес.</span>' );

            setTimeout(function(){
                setInputSize($('.js-calculator__months', $period_master));
                setInputSize($('.js-calculator__years', $period_master));
            }, 1);

            $('.period-output').html((data.from / 12 | 0) + ' год. и ' + data.from % 12 +' мес.');

            $('[name="period"]').val(data.from);

        },
        onChange: function (data){

            $period_master.removeClass('disabled');

            $('.calculator-box__output').html();

            setInputSize($('.js-calculator__months', $period_master));
            setInputSize($('.js-calculator__years', $period_master));

            $('.js-calculator__years', $period_master).val((data.from / 12 | 0));
            $('.js-calculator__months', $period_master).val(data.from % 12);

        },
        onFinish: function(data){

            $('.period-output').html((data.from / 12 | 0) + ' год. и ' + data.from % 12 +' мес.');
            $('[name="period"]').val(data.from);

            if( data.from === 0 || calculator_amount_instance.old_from === 0 ){
                $('.js-button__form').prop('disabled', true);
            } else {
                $('.js-button__form').prop('disabled', false);
            }

            // For the demo
            // $('.js-button__form').prop('disabled', true);
            // setTimeout(function(){
            //     $('.js-button__form').prop('disabled', false);
            // }, 2000);

        },
        onUpdate: function(data){

            setInputSize($('.js-calculator__months', $period_master));
            setInputSize($('.js-calculator__years', $period_master));

            $('.js-calculator__years', $period_master).val((data.from / 12 | 0));
            $('.js-calculator__months', $period_master).val(data.from % 12);

            $('.period-output').html((data.from / 12 | 0) + ' год. и ' + data.from % 12 +' мес.');

            $('[name="period"]').val(data.from);

            if( data.from === 0 || calculator_amount_instance.old_from === 0 ){
                $('.js-button__form').prop('disabled', true);
            } else {
                $('.js-button__form').prop('disabled', false);
            }
        }
    });

    
    var calculator_period_instance = $calculator_period.data('ionRangeSlider');

    $('.js-calculator__years', $period_master).on('focus', function(){

        $(this)[0].setSelectionRange(99999, 99999);

    }).on('input change', function(){

        this.value = this.value.replace(/[^0-9]/g, '');

        if( $(this).val() * 12 + Number($('.js-calculator__months', $period_master).val()) > currencies[currency].period_max ){

            $(this).val( (currencies[currency].period_max / 12 ) );
            $('.js-calculator__months', $period_master).val( currencies[currency].period_max % 12 );

        }

        setInputSize($('.js-calculator__years', $period_master));

    }).on('blur', function(){

        if( $(this).val() * 12 + Number($('.js-calculator__months', $period_master).val()) > currencies[currency].period_max ){
            
            calculator_period_instance.update({
                from: currencies[currency].period_max
            });

            $(this).val( (currencies[currency].period_max / 12 | 0) );
            $('.js-calculator__months', $period_master).val( currencies[currency].period_max % 12 );

        } else {
            calculator_period_instance.update({
                from: $(this).val() * 12 + Number($('.js-calculator__months', $period_master).val())
            });
        }

        $('.period-output').html( (calculator_period_instance.result.from / 12 | 0) + ' год. и ' + calculator_period_instance.result.from % 12 +' мес.');

    }).on('keypress', function(e){
        
        if(e.which === 13){
            
            if( $(this).val() * 12 + Number($('.js-calculator__months', $period_master).val()) > currencies[currency].period_max ){
            
                calculator_period_instance.update({
                    from: currencies[currency].period_max
                });

                $(this).val( (currencies[currency].period_max / 12 | 0) );
                $('.js-calculator__months', $period_master).val( currencies[currency].period_max % 12 );

            } else {
                calculator_period_instance.update({
                    from: $(this).val() * 12 + Number($('.js-calculator__months', $period_master).val())
                });
            }

            $(this).blur();

            $('.period-output').html( (calculator_period_instance.result.from / 12 | 0) + ' год. и ' + calculator_period_instance.result.from % 12 +' мес.');
        }

    });

    $('.js-calculator__months', $period_master).on('focus', function(){

        $(this)[0].setSelectionRange(99999, 99999);

    }).on('input change', function(){

        this.value = this.value.replace(/[^0-9]/g, '');

        var years = Number($('.js-calculator__years', $period_master).val());

        if( this.value == 12 ){
            
            this.value = 0;
            $('.js-calculator__years', $period_master).val(years + 1);

        } else if( this.value > 12 ){

            this.value = 12;
        }

        if( $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0) > currencies[currency].period_max ){
            $('.js-calculator__years', $period_master).val( (currencies[currency].period_max / 12 | 0) );
            $(this).val( currencies[currency].period_max % 12 );
        }

        setInputSize($('.js-calculator__months', $period_master));
        setInputSize($('.js-calculator__years', $period_master));

    }).on('blur', function(){

        if( $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0) > currencies[currency].period_max ){
            
            calculator_period_instance.update({
                from: $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0)
            });

        } else {
            calculator_period_instance.update({
                from: $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0)
            });
        }

        $('.period-output').html( (calculator_period_instance.result.from / 12 | 0) + ' год. и ' + calculator_period_instance.result.from % 12 +' мес.');

    }).on('keypress', function(e){
        
        if(e.which === 13){
            
            if( $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0) > currencies[currency].period_max ){
            
                calculator_period_instance.update({
                    from: $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0)
                });

            } else {
                calculator_period_instance.update({
                    from: $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0)
                });
            }

            $(this).blur();

            $('.period-output').html( (calculator_period_instance.result.from / 12 | 0) + ' год. и ' + calculator_period_instance.result.from % 12 +' мес.');    
        }

    });

    $('.calculator-box__button', $period_master ).on('click', function(){
        $period_master.addClass('disabled');

        $('.js-calculator__years', $period_master).val(0);
        $('.js-calculator__months', $period_master).val(0);
        
        calculator_period_instance.update({
            from: 0
        });

        $('.period-output').html('-');
    });

}


// Calculator limit
if( $('.calculator-limit').length > 0 ){

    limit = $('.js-card__type:checked').val() + $('.js-card__payment:checked').val();
    
    setLimit = function(limit){
        
        calculator_limit_instance.update({
            min: limits[limit].limit_min,
            max: limits[limit].limit_max,
            from: limits[limit].limit_from,
            step: limits[limit].limit_step,
        });

        var calculator_img = new Image();
        calculator_img.src = limits[limit].limit_card;
        calculator_img.onload = function(){
            $('.calculator__card-preview').prop('src', calculator_img.src);
        };

        $('[name="limit"]').val(limits[limit].limit_from);
    }

    $('.js-card__type, .js-card__payment').on('change', function(){

        limit = $('.js-card__type:checked').val() + $('.js-card__payment:checked').val();
        setLimit( limit );

    });

    
    var $limit_master = $('.calculator-limit'),
        $limit_input = $('.calculator-box__input', $limit_master),
        $limit_input_id = $limit_input.prop('id'),
        $limit_output = $('.calculator-box__output', $limit_master),
        $limit_id = Math.floor((Math.random() * 1000) + 1),
        $limit_placeholder = $('.calculator-box__placeholder', $limit_master).html();

    var $calculator_limit = $('#'+ $limit_input_id +'').ionRangeSlider({
        min: limits[limit].limit_min,
        max: limits[limit].limit_max,
        from: limits[limit].limit_from,
        step: limits[limit].limit_step,
        hide_min_max: true,
        hide_from_to: true,
        skin: 'fibank',
        onStart: function (data){

            if( $limit_master.is('.disabled') ){
                setTimeout(function(){
                    var calculator_limit_instance = $calculator_limit.data('ionRangeSlider');
                    calculator_limit_instance.update({
                        from: 0
                    });
                }, 2);
            }
            
            $limit_output.html('<label for="'+ $limit_id +'" class="sr">'+ $limit_placeholder +'</label><input id="'+ $limit_id +'" pattern="[0-9]*" title="" type="text" class="calculator-box__input-element js-calculator__value" value="'+ ReplaceNumberWithSpace(data.from) +'" maxlength="'+ limits[limit].limit_max.toString().length + 1 +'" /> <span class="calculator-box__input-postfix js-currency text-uppercase">'+ currency +'</span>');

            setInputSize($('.js-calculator__value', $limit_master));

            $('[name="limit"]').val(data.from);

        },
        onChange: function (data){

            $limit_master.removeClass('disabled');

            $('.js-calculator__value', $limit_master).val(ReplaceNumberWithSpace(data.from));

            setInputSize($('.js-calculator__value', $limit_master));

        },
        onFinish: function(data){

            $('[name="limit"]').val(data.from);

            if( data.from != 0 ){
                $('.js-button__form').prop('disabled', false);
            } else {
                $('.js-button__form').prop('disabled', true);
            }

        },
        onUpdate: function(data){
            
            $('.js-calculator__value', $limit_master).val(ReplaceNumberWithSpace(data.from));

            setInputSize($('.js-calculator__value', $limit_master));

            $('[name="limit"]').val(data.from);

            if( data.from === 0 ){
                $('.js-button__form').prop('disabled', false);
            } else {
                $('.js-button__form').prop('disabled', true);
            }
        }
    });

    
    var calculator_limit_instance = $calculator_limit.data('ionRangeSlider');

    $('.js-calculator__value', $limit_master).on('focus', function(){

        $(this)[0].setSelectionRange(99999, 99999);

    }).on('input change', function(){

        this.value = this.value.replace(/[^0-9]/g, '');

        if( $(this).val() >= limits[limit].limit_max ){
            this.value = ReplaceNumberWithSpace(limits[limit].limit_max);
        } else {
            this.value = ReplaceNumberWithSpace(this.value);
        }

        setInputSize($('.js-calculator__value', $limit_master));

    }).on('blur', function(){

        if( $(this).val().replace(/ /g , '') > limits[limit].limit_max ){

            $(this).val( ReplaceNumberWithSpace(limits[limit].limit_max) );
            
            calculator_limit_instance.update({
                from: currencies[limit].limit_max
            });

        } else {
            calculator_limit_instance.update({
                from: $(this).val().replace(/ /g , '')
            });
        }

    }).on('keypress', function(e){
        
        if(e.which === 13){               
            
            if( $(this).val().replace(/ /g , '') > limits[limit].max ){

                $(this).val( ReplaceNumberWithSpace(limits[limit].max) );
                
                calculator_limit_instance.update({
                    from: limits[limit].max
                });

            } else {
                calculator_limit_instance.update({
                    from: $(this).val().replace(/ /g , '')
                });
            }

            $(this).blur();
        }

    });

    $('.calculator-box__button', $limit_master ).on('click', function(){
        
        $limit_master.addClass('disabled');

        $('.js-calculator__value', $limit_master).val(0);
        
        calculator_limit_instance.update({
            from: 0
        });

        $('[name="limit"]').val(0);
    });

}


// Calculator filter by amount
if( $('.calculator-filter-amount').length > 0 ){
    
    var $amount_master = $('.calculator-filter-amount'),
        $amount_input = $('.calculator-box__input', $amount_master),
        $amount_input_id = $amount_input.prop('id'),
        $amount_output = $('.calculator-box__output', $amount_master),
        $amount_id = Math.floor((Math.random() * 1000) + 1),
        $amount_placeholder = $('.calculator-box__placeholder', $amount_master).html();

    var $calculator_amount = $('#'+ $amount_input_id +'').ionRangeSlider({
        min: currencies[currency].amount_min,
        max: currencies[currency].amount_max,
        from: currencies[currency].amount_from,
        step: currencies[currency].amount_step,
        hide_min_max: true,
        hide_from_to: true,
        skin: 'fibank',
        onStart: function (data){

            if( $amount_master.is('.disabled') ){
                setTimeout(function(){
                    var calculator_amount_instance = $calculator_amount.data('ionRangeSlider');
                    calculator_amount_instance.update({
                        from: 0
                    });
                }, 2);
            }
            
            $amount_output.html('<label for="'+ $amount_id +'" class="sr">'+ $amount_placeholder +'</label><input id="'+ $amount_id +'" pattern="[0-9]*" title="" type="text" class="calculator-box__input-element js-calculator__value" value="'+ ReplaceNumberWithSpace(data.from) +'" maxlength="'+ currencies[currency].amount_max.toString().length + 1 +'" /> <span class="calculator-box__input-postfix js-currency text-uppercase">'+ currency +'</span>');

            setInputSize($('.js-calculator__value', $amount_master));

            $('[name="amount"]').val(data.from);

        },
        onChange: function (data){

            $amount_master.removeClass('disabled');

            $('.js-calculator__value', $amount_master).val(ReplaceNumberWithSpace(data.from));

            setInputSize($('.js-calculator__value', $amount_master));

        },
        onFinish: function(data){

            $('[name="amount"]').val(data.from);

            if( data.from != 0 && calculator_period_instance.old_from != 0 ){
                console.log(data.from);
            }

        },
        onUpdate: function(data){
            
            $('.js-calculator__value', $amount_master).val(ReplaceNumberWithSpace(data.from));

            setInputSize($('.js-calculator__value', $amount_master));

            $('[name="amount"]').val(data.from);

            if( data.from != 0 && calculator_period_instance.old_from != 0 ){
                console.log(data.from);
            }
        }
    });

    
    var calculator_amount_instance = $calculator_amount.data('ionRangeSlider');

    $('.js-calculator__value', $amount_master).on('focus', function(){

        $(this)[0].setSelectionRange(99999, 99999);

    }).on('input change', function(){

        this.value = this.value.replace(/[^0-9]/g, '');

        if( $(this).val() >= currencies[currency].amount_max ){
            this.value = ReplaceNumberWithSpace(currencies[currency].amount_max);
        } else {
            this.value = ReplaceNumberWithSpace(this.value);
        }

        setInputSize($('.js-calculator__value', $amount_master));

    }).on('blur', function(){

        if( $(this).val().replace(/ /g , '') > currencies[currency].amount_max ){

            $(this).val( ReplaceNumberWithSpace(currencies[currency].amount_max) );
            
            calculator_amount_instance.update({
                from: currencies[currency].amount_max
            });

        } else {
            calculator_amount_instance.update({
                from: $(this).val().replace(/ /g , '')
            });
        }

    }).on('keypress', function(e){
        
        if(e.which === 13){               
            
            if( $(this).val().replace(/ /g , '') > currencies[currency].max ){

                $(this).val( ReplaceNumberWithSpace(currencies[currency].max) );
                
                calculator_amount_instance.update({
                    from: currencies[currency].max
                });

            } else {
                calculator_amount_instance.update({
                    from: $(this).val().replace(/ /g , '')
                });
            }

            $(this).blur();
        }

    });

    $('.calculator-box__button', $amount_master ).on('click', function(){
        
        $amount_master.addClass('disabled');

        $('.js-calculator__value', $amount_master).val(0);
        
        calculator_amount_instance.update({
            from: 0
        });

        $('[name="amount"]').val(0);
    });

}


// Calculator filter by period
if( $('.calculator-filter-period').length > 0 ){
    
    var $period_master = $('.calculator-filter-period'),
        $period_input = $('.calculator-box__input', $period_master),
        $period_input_id = $period_input.prop('id'),
        $period_output = $('.calculator-box__output', $period_master),
        $period_id_1 = Math.floor((Math.random() * 1000) + 1),
        $period_id_2 = Math.floor((Math.random() * 1000) + 1);

    var $calculator_period = $('#'+ $period_input_id +'').ionRangeSlider({
        min: currencies[currency].period_min,
        max: currencies[currency].period_max,
        from: currencies[currency].period_from,
        step: currencies[currency].period_step,
        hide_min_max: true,
        hide_from_to: true,
        skin: 'fibank',
        onStart: function (data){

            if( $period_master.is('.disabled') ){
                setTimeout(function(){
                    var calculator_period_instance = $calculator_period.data('ionRangeSlider');
                    calculator_period_instance.update({
                        from: 0
                    });
                }, 2);
            }
            
            $period_output.html('<label for="'+ $period_id_1 +'" class="sr">мес.</label><input id="'+ $period_id_1 +'" pattern="[0-9]*" title="" type="text" class="calculator-box__input-element js-calculator__years" value="'+ (data.from / 12 | 0) +'" maxlength="3" />' + ' <span class="calculator-box__input-postfix">год. и</span> ' + '<label for="'+ $period_id_2 +'" class="sr">мес.</label><input id="'+ $period_id_2 +'" pattern="[0-9]*" title="" type="text" class="calculator-box__input-element js-calculator__months" value="'+ data.from % 12 +'" maxlength="2" /> <span class="calculator-box__input-postfix">мес.</span>' );

            setTimeout(function(){
                setInputSize($('.js-calculator__months', $period_master));
                setInputSize($('.js-calculator__years', $period_master));
            }, 1);

            $('[name="period"]').val(data.from);

        },
        onChange: function (data){

            $period_master.removeClass('disabled');

            $('.calculator-box__output').html();

            setInputSize($('.js-calculator__months', $period_master));
            setInputSize($('.js-calculator__years', $period_master));

            $('.js-calculator__years', $period_master).val((data.from / 12 | 0));
            $('.js-calculator__months', $period_master).val(data.from % 12);

        },
        onFinish: function(data){

            $('[name="period"]').val(data.from);

            if( data.from != 0 && calculator_amount_instance.old_from != 0 ){
                console.log(data.from);
            }

        },
        onUpdate: function(data){

            setInputSize($('.js-calculator__months', $period_master));
            setInputSize($('.js-calculator__years', $period_master));

            $('.js-calculator__years', $period_master).val((data.from / 12 | 0));
            $('.js-calculator__months', $period_master).val(data.from % 12);

            $('[name="period"]').val(data.from);

            if( data.from != 0 && calculator_amount_instance.old_from != 0 ){
                console.log(data.from);
            }
        }
    });
    
    var calculator_period_instance = $calculator_period.data('ionRangeSlider');

    $('.js-calculator__years', $period_master).on('focus', function(){

        $(this)[0].setSelectionRange(99999, 99999);

    }).on('input change', function(){

        this.value = this.value.replace(/[^0-9]/g, '');

        if( $(this).val() * 12 + Number($('.js-calculator__months', $period_master).val()) > currencies[currency].period_max ){

            $(this).val( (currencies[currency].period_max / 12 ) );
            $('.js-calculator__months', $period_master).val( currencies[currency].period_max % 12 );

        }

        setInputSize($('.js-calculator__years', $period_master));

    }).on('blur', function(){

        if( $(this).val() * 12 + Number($('.js-calculator__months', $period_master).val()) > currencies[currency].period_max ){
            
            calculator_period_instance.update({
                from: currencies[currency].period_max
            });

            $(this).val( (currencies[currency].period_max / 12 | 0) );
            $('.js-calculator__months', $period_master).val( currencies[currency].period_max % 12 );

        } else {
            calculator_period_instance.update({
                from: $(this).val() * 12 + Number($('.js-calculator__months', $period_master).val())
            });
        }

    }).on('keypress', function(e){
        
        if(e.which === 13){
            
            if( $(this).val() * 12 + Number($('.js-calculator__months', $period_master).val()) > currencies[currency].period_max ){
            
                calculator_period_instance.update({
                    from: currencies[currency].period_max
                });

                $(this).val( (currencies[currency].period_max / 12 | 0) );
                $('.js-calculator__months', $period_master).val( currencies[currency].period_max % 12 );

            } else {
                calculator_period_instance.update({
                    from: $(this).val() * 12 + Number($('.js-calculator__months', $period_master).val())
                });
            }

            $(this).blur();
        }

    });

    $('.js-calculator__months', $period_master).on('focus', function(){

        $(this)[0].setSelectionRange(99999, 99999);

    }).on('input change', function(){

        this.value = this.value.replace(/[^0-9]/g, '');

        var years = Number($('.js-calculator__years', $period_master).val());

        if( this.value == 12 ){
            
            this.value = 0;
            $('.js-calculator__years', $period_master).val(years + 1);

        } else if( this.value > 12 ){

            this.value = 12;
        }

        if( $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0) > currencies[currency].period_max ){
            $('.js-calculator__years', $period_master).val( (currencies[currency].period_max / 12 | 0) );
            $(this).val( currencies[currency].period_max % 12 );
        }

        setInputSize($('.js-calculator__months', $period_master));
        setInputSize($('.js-calculator__years', $period_master));

    }).on('blur', function(){

        if( $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0) > currencies[currency].period_max ){
            
            calculator_period_instance.update({
                from: $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0)
            });

        } else {
            calculator_period_instance.update({
                from: $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0)
            });
        }

    }).on('keypress', function(e){
        
        if(e.which === 13){
            
            if( $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0) > currencies[currency].period_max ){
            
                calculator_period_instance.update({
                    from: $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0)
                });

            } else {
                calculator_period_instance.update({
                    from: $(this).val() % 12 + ($('.js-calculator__years', $period_master).val() * 12 | 0)
                });
            }

            $(this).blur();
        }

    });

    $('.calculator-box__button', $period_master ).on('click', function(){
        $period_master.addClass('disabled');

        $('.js-calculator__years', $period_master).val(0);
        $('.js-calculator__months', $period_master).val(0);
        
        calculator_period_instance.update({
            from: 0
        });
    });

}

$('.calculator-box__placeholder').on('click', function(){
    var calcBox = $(this).parents('.calculator-box');
    calcBox.removeClass('disabled');
    setTimeout(function(){
        $('.calculator-box__input-element:first', calcBox).focus();
    }, 100);
});


// Offers
$('.js-expand__aside-offer').on('click', function(){
    $(this).parents('.aside-offer').addClass('aside-offer--expanded');
});

$('.js-close__aside-offer').on('click', function(){
    $(this).parents('.aside-offer').removeClass('aside-offer--expanded');
});